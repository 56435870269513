import { PricingRule } from './state';

export const LOAD_PRICING_RULES_ACTION_TYPE = 'LOAD_PRICING_RULES' as const;
export const loadPricingRulesAction = () => ({
  type: LOAD_PRICING_RULES_ACTION_TYPE
});
export type LoadPricingRulesAction = ReturnType<typeof loadPricingRulesAction>;

export const PRICING_RULES_LOADED_ACTION_TYPE = 'PRICING_RULES_LOADED' as const;
export const pricingRulesLoadedAction = ({ rules }: { rules: Omit<PricingRule, 'status'>[] }) => ({
  type: PRICING_RULES_LOADED_ACTION_TYPE,
  payload: {
    rules
  }
});
export type PricingRulesLoadedAction = ReturnType<typeof pricingRulesLoadedAction>;

export const LOAD_PRICING_RULES_FAILED_ACTION_TYPE = 'LOAD_PRICING_RULES_FAILED';
export const loadPricingRulesFailedAction = () => ({
  type: LOAD_PRICING_RULES_FAILED_ACTION_TYPE
});

export const EDIT_PRICING_RULE_ACTION_TYPE = 'EDIT_PRICING_RULE' as const;
export const editPricingRule = (
  ruleId: string,
  ruleProperties: Partial<Pick<PricingRule, 'conditions' | 'event' | 'enabled'>>
) => ({
  type: EDIT_PRICING_RULE_ACTION_TYPE,
  payload: {
    ruleId,
    ruleProperties
  }
});
export type EditPricingRuleAction = ReturnType<typeof editPricingRule>;

export const RENAME_PRICING_RULE_ACTION_TYPE = 'RENAME_PRICING_RULE' as const;
export const renamePricingRule = (ruleId: string, name: string) => ({
  type: RENAME_PRICING_RULE_ACTION_TYPE,
  payload: {
    ruleId,
    name
  }
});
export type RenamePricingRuleAction = ReturnType<typeof renamePricingRule>;

export const DELETE_PRICING_RULE_ACTION_TYPE = 'DELETE_PRICING_RULE' as const;
export const deletePricingRule = (ruleId: string) => ({
  type: DELETE_PRICING_RULE_ACTION_TYPE,
  payload: {
    ruleId
  }
});
export type DeletePricingRuleAction = ReturnType<typeof deletePricingRule>;

export const SAVE_PRICING_RULES_ACTION_TYPE = 'SAVE_PRICING_RULES';
export const savePricingRulesAction = () => ({
  type: SAVE_PRICING_RULES_ACTION_TYPE
});
export type SavePricingRulesAction = ReturnType<typeof savePricingRulesAction>;

export const SAVED_PRICING_RULES_ACTION_TYPE = 'SAVED_PRICING_RULES';
export const savedPricingRulesAction = () => ({
  type: SAVED_PRICING_RULES_ACTION_TYPE
});
export type SavedPricingRulesAction = ReturnType<typeof savedPricingRulesAction>;
