import { TypeInfo } from './schema';

export type Operator = {
  operator: string;
  typeInfo?: TypeInfo;
};

const alwaysOperators = [
  { operator: 'isNone' },
  { operator: 'isDefined' },
  { operator: 'isNotNone' },
  { operator: 'isNotDefined' }
];

const comparisonOperators = [
  { operator: 'greaterThan' },
  { operator: 'lessThan' },
  { operator: 'greaterThanInclusive' },
  { operator: 'lessThanInclusive' }
];

const equalityOperators = [{ operator: 'equal' }, { operator: 'notEqual' }];

const listOperators = [{ operator: 'in' }, { operator: 'notIn' }];

const inclusionOperators = [{ operator: 'contains' }, { operator: 'doesNotContain' }];

export const getOperatorsForFact = (factInfo?: TypeInfo): Operator[] => {
  if (factInfo) {
    switch (factInfo.type) {
      case 'number':
        return [
          ...alwaysOperators,
          ...comparisonOperators.map((o) => ({ ...o, typeInfo: factInfo })),
          ...equalityOperators.map((o) => ({ ...o, typeInfo: factInfo }))
        ];
      case 'string':
        return [
          ...alwaysOperators,
          ...equalityOperators.map((o) => ({ ...o, typeInfo: factInfo })),
          ...listOperators.map((o) => ({
            ...o,
            typeInfo: {
              type: 'list',
              typeInfo: factInfo
            }
          })),
          ...inclusionOperators.map((o) => ({ ...o, typeInfo: factInfo }))
        ];
      case 'boolean':
        return [
          ...alwaysOperators,
          ...equalityOperators.map((o) => ({ ...o, typeInfo: factInfo }))
        ];
      case 'enumerated':
        return [
          ...alwaysOperators,
          ...equalityOperators.map((o) => ({ ...o, typeInfo: factInfo })),
          ...listOperators.map((o) => ({
            ...o,
            typeInfo: {
              type: 'list',
              typeInfo: factInfo
            }
          }))
        ];
      case 'list':
        return [
          ...alwaysOperators,
          ...inclusionOperators.map((o) => ({ ...o, typeInfo: factInfo.typeInfo }))
        ];
    }
  }

  return alwaysOperators;
};
