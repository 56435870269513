import { OwnUpComponentLibraryProvider } from '@rategravity/own-up-component-library';
import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library-legacy';
import { InternalAuthProvider } from '@rategravity/widgets-react/modules/auth/internal/hooks/internal-auth-provider';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { App } from './components/app';
import { EvaluateLeadPage } from './pages/evaluate-leads';
import { LenderConfigurationApp } from './pages/lender-configuration/app';
import { PricingRulesApp } from './pages/pricing-rules/app';
import { RulesPriorityPage } from './pages/rules-priority';
import { RulesValidationPage } from './pages/rules-validation';
import { TestLeadPage } from './pages/test-leads';
import { store } from './redux/store';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.DEPLOY_ENV,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <InternalAuthProvider apiBaseUrl={window.location.origin + '/api'}>
    <Provider store={store}>
      <Helmet>
        <title>Lender Configuration</title>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/test-leads">
            <OwnUpComponentLibraryProvider>
              <TestLeadPage />
            </OwnUpComponentLibraryProvider>
          </Route>
          <Route path="/evaluate-leads">
            <OwnUpComponentLibraryProvider>
              <EvaluateLeadPage />
            </OwnUpComponentLibraryProvider>
          </Route>
          <Route path="/lender-configuration" component={LenderConfigurationApp} />
          <Route path="/pricing-rules" component={PricingRulesApp} />
          <Route path="/rules-validation">
            <OwnUpComponentLibraryProvider>
              <RulesValidationPage />
            </OwnUpComponentLibraryProvider>
          </Route>
          <Route path="/rules-priority">
            <RulesPriorityPage />
          </Route>
          <Route path="/">
            <OwnUpThemeWrapper>
              <App />
            </OwnUpThemeWrapper>
          </Route>
        </Switch>
      </Router>
    </Provider>
  </InternalAuthProvider>
);
