import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  deletePricingRulesApi,
  fetchPricingRulesApi,
  PricingRuleResult,
  updatePricingRulesApi,
  UpdatePricingRulesPayload
} from '../../modules/api/pricing-rules';
import {
  LOAD_PRICING_RULES_ACTION_TYPE,
  loadPricingRulesFailedAction,
  pricingRulesLoadedAction,
  SAVE_PRICING_RULES_ACTION_TYPE,
  savedPricingRulesAction
} from './actions';
import { deletePayloadSelector, updatePayloadSelector } from './selector';

function* fetchPricingRules() {
  try {
    const result: PricingRuleResult = yield call(fetchPricingRulesApi);
    yield put(pricingRulesLoadedAction({ rules: result }));
  } catch (err) {
    console.log('Error loading pricing rules', err);
    yield put(loadPricingRulesFailedAction());
  }
}

function* savePricingRules() {
  try {
    const updatePayload: UpdatePricingRulesPayload = yield select(updatePayloadSelector);
    const deletePayload: ReturnType<typeof deletePayloadSelector> =
      yield select(deletePayloadSelector);
    yield all([
      call(updatePricingRulesApi, updatePayload),
      call(deletePricingRulesApi, deletePayload)
    ]);
    yield put(savedPricingRulesAction());
  } catch (err) {
    console.error('Error updating pricing rules', err);
  }
}

export function* pricingRulesSaga() {
  yield all([takeEvery([LOAD_PRICING_RULES_ACTION_TYPE], fetchPricingRules)]);
  yield all([takeEvery([SAVE_PRICING_RULES_ACTION_TYPE], savePricingRules)]);
}
