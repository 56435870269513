import { OwnUpDropdown, OwnUpMenuItem } from '@rategravity/own-up-component-library';
import { ConditionProperties } from 'json-rules-engine';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { camelCaseToStartCase } from '../../modules/rules/format-rules';
import { Operator } from '../../modules/rules/operators';
import { FactInfo } from '../../modules/rules/schema';
import { ParameterEditor } from '../parameter-editor';
import { TypeEditor } from '../parameter-editor/type-editor';
import { ErrorText } from './error-text';

type FactEditorProps = {
  path: string;
  factId: string;
  validationErrors: Map<string, string>;
  conditionSelect: React.ReactNode;
  removeButton: React.ReactNode;
  condition: ConditionProperties;
  allFacts: FactInfo[];
  operatorOptions: Operator[];
  onConditionUpdate: (comparison: ConditionProperties) => void;
};

const ConditionSelectContainer = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: row;
`;

const RemoveButtonContainer = styled.div`
  grid-column: 5;
`;

/**
 * Editor for comparing a value to a fact
 * Renders the appropriate list of operators and
 * the appropriate editor for the operator.
 */
export const FactEditor = ({
  path,
  factId,
  validationErrors,
  conditionSelect,
  condition,
  allFacts,
  operatorOptions,
  onConditionUpdate,
  removeButton
}: FactEditorProps) => {
  const operatorInfo = operatorOptions.find(({ operator }) => operator === condition.operator);
  const error = validationErrors.get(path);
  const factOptions = allFacts.filter(({ factId: id }) => factId !== id);
  const curFact = allFacts.find(({ factId: id }) => factId === id);

  const onConditionUpdateWrapper = useCallback(
    (data: Record<string, unknown>) => {
      if ('fact' in data && typeof data.fact === 'string') {
        // Translate a nested fact back to the backend format
        // `base.nested.field` -> { fact: 'base', path: 'nested.field' }
        const [factName, ...factPath] = data.fact.split('.');
        data = {
          ...data,
          fact: factName,
          // Use path if the fact had a nested format,
          //  otherwise use the existing path if it exists,
          //  otherwise set to undefined.
          // Don't send an empty string as that messes up the backend
          path: factPath.join('.') || data.path || undefined
        };
      }
      return onConditionUpdate({ ...condition, ...data });
    },
    [condition, onConditionUpdate]
  );
  return (
    <React.Fragment>
      <ConditionSelectContainer>
        {conditionSelect}
        {curFact?.params && (
          <ParameterEditor
            params={curFact.params}
            factOptions={[]}
            data={condition.params ?? {}}
            onDataUpdate={(params) => onConditionUpdateWrapper(params)}
          />
        )}
      </ConditionSelectContainer>
      <OwnUpDropdown
        value={condition.operator}
        onChange={(event) => {
          onConditionUpdateWrapper({
            operator: event.target.value as string,
            value: undefined
          });
        }}
      >
        {operatorOptions.map(({ operator }) => (
          <OwnUpMenuItem key={operator} value={operator}>
            {camelCaseToStartCase(operator)}
          </OwnUpMenuItem>
        ))}
      </OwnUpDropdown>
      {operatorInfo && operatorInfo.typeInfo && (
        <TypeEditor
          typeInfo={operatorInfo.typeInfo}
          name={''}
          factOptions={factOptions}
          optional={false}
          data={condition.value}
          onDataUpdate={(value) => {
            onConditionUpdateWrapper({ ...condition, value });
          }}
        />
      )}
      <RemoveButtonContainer>{removeButton}</RemoveButtonContainer>
      <ErrorText error={error} />
    </React.Fragment>
  );
};
