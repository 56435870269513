import { FactInfo, RuleInfo } from './types';

export const pricingRules: RuleInfo[] = [
  {
    type: 'pricingRule',
    params: {
      operations: {
        optional: false,
        type: 'list',
        typeInfo: {
          type: 'object',
          properties: {
            operation: {
              optional: false,
              type: 'enumerated',
              values: ['add', 'subtract', 'multiply', 'divide', 'set'],
              description: 'Operation to apply to the value'
            },
            operand: {
              optional: false,
              type: 'number',
              description: 'Value to apply to the operation'
            }
          }
        },
        description: 'Ordered list of operations to apply to the pricing rule'
      },
      score: {
        optional: false,
        type: 'number',
        description: 'The base score this match will receive before any modifiers are applied.'
      }
    }
  }
];

export const pricingRulesFacts: FactInfo[] = [
  {
    factId: 'lenderId',
    type: 'string',
    description: 'ID of the matched lender'
  },
  { factId: 'campaign', type: 'string', description: 'The campaign of the matched lender' },
  {
    factId: 'leadCost',
    type: 'number',
    description: 'The base cost of the lead'
  },
  {
    factId: 'budgetType',
    type: 'string',
    description: 'The type of budget to use'
  },
  {
    factId: 'method',
    type: 'enumerated',
    values: ['auto', 'opt-in'],
    description: 'The method of selling the lead'
  },
  {
    factId: 'processDelayedLeadTimestamp',
    type: 'number',
    description: 'If the lead is to be delayed, the timestmap to sell the lead'
  },
  {
    factId: 'isClearinghouse',
    type: 'boolean',
    description: 'If the lead is a clearinghouse lead'
  },
  {
    factId: 'budgetCategorization',
    type: 'list',
    typeInfo: {
      type: 'string'
    },
    description: 'The budget categorizations for the lender'
  }
];
