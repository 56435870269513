import { createSelector } from 'reselect';
import {
  DeleteLenderEndpointRulesPayload,
  UpdateLenderEndpointRulesPayload
} from '../../modules/api/lender-endpoint-rules';
import { AppState } from '../store';

export const lenderEndpointRulesSelector = (
  state: AppState,
  { lenderId }: { lenderId: string }
) => {
  const rules = state.lenderEndpointRules[lenderId]?.rules;
  return Object.entries(rules ?? {})
    .filter(([_, v]) => v.status !== 'deleted')
    .map(([ruleId, rule]) => ({ ...rule, ruleId }));
};

export const lenderEndpointRuleSelector = (
  state: AppState,
  { lenderId, ruleId }: { lenderId: string; ruleId: string }
) => {
  return state.lenderEndpointRules[lenderId]?.rules[ruleId];
};

/**
 * Gets the payload for the updateLenderEndpointRules lambda
 */
export const updatePayloadSelector = (
  state: AppState,
  lenderId: string
): UpdateLenderEndpointRulesPayload => {
  const rules = Object.values(state.lenderEndpointRules[lenderId].rules)
    .filter((rule) => rule.status === 'edited')
    .map((rule) => [rule.name, rule]);
  return {
    lenderId,
    rules: Object.fromEntries(rules)
  };
};

export const deletePayloadSelector = (
  state: AppState,
  lenderId: string
): DeleteLenderEndpointRulesPayload => {
  const deletedRules = Object.values(state.lenderEndpointRules[lenderId].rules)
    .filter((rule) => rule.status === 'deleted')
    .map((rule) => rule.name);
  return {
    lenderId,
    rules: deletedRules
  };
};

export const pendingSaveSelector = createSelector(
  updatePayloadSelector,
  deletePayloadSelector,
  (updatePayload, deletePayload) => {
    return Object.values(updatePayload.rules || {}).length > 0 || deletePayload.rules.length > 0;
  }
);

export const savingSelector = (state: AppState, lenderId: string) =>
  state.lenderEndpointRules[lenderId]?.savingRules;

/**
 * Return the loading state for lender endpoint rules
 */
export const lenderEndpointRulesLoadingSelector = (state: AppState, lenderId: string) => {
  return state.lenderEndpointRules[lenderId]?.loading ?? true;
};

/**
 * Return the error state for lender endpoint rules
 */
export const lenderEndpointRulesErrorSelector = (state: AppState, lenderId: string) => {
  return state.lenderEndpointRules[lenderId]?.error ?? false;
};
