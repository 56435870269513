import styled from 'styled-components';

/**
 * Generic container for a table with some margins
 */
export const TableContainer = styled.div`
  margin: 24px 0;
  table {
    width: 100%;
    margin-top: 16px;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    text-align: center;
  }
`;
