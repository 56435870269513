import {
  IconLink,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSmallHeadlineBook,
  OwnUpUrgentErrorNotification
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadLenderConfigAction } from '../../redux/lender-configuration/actions';
import {
  lenderBudgetsError,
  lenderBudgetsLoading
} from '../../redux/lender-configuration/selector';
import { Budgets } from './budgets';

export const LenderConfiguration = () => {
  const dispatch = useDispatch();
  const loading = useSelector(lenderBudgetsLoading);
  const error = useSelector(lenderBudgetsError);

  useEffect(() => {
    dispatch(loadLenderConfigAction());
  }, [dispatch]);

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            Lender Configuration
          </OwnUpHeadlineBook>
          {loading && (
            <OwnUpSmallHeadlineBook variant="h3" style={{ textAlign: 'center' }}>
              Loading...
            </OwnUpSmallHeadlineBook>
          )}
          {error && (
            <OwnUpUrgentErrorNotification description="Error loading config, check authentication"></OwnUpUrgentErrorNotification>
          )}
          {!loading && !error && (
            <React.Fragment>
              <IconLink icon={<ArrowRightIcon />} link="/rules-validation">
                Go to rules validation
              </IconLink>
              <IconLink icon={<ArrowRightIcon />} link="/rules-priority">
                Go to rule priority visualizer
              </IconLink>
              <IconLink icon={<ArrowRightIcon />} link="/pricing-rules">
                Go to pricing rules
              </IconLink>
              <Budgets />
            </React.Fragment>
          )}
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
