import { RuleProperties } from 'json-rules-engine';
import * as t from 'type-shift';
import { PricingRule } from '../../redux/pricing-rules/state';
import { loggedInFetch } from '../authentication';
import { topLevelConditionConverter } from './types';

export type PricingRuleResult = Omit<PricingRule, 'status'>[];

const pricingRulesConverter = t.array(
  t.strict<Omit<PricingRule, 'status'>>({
    name: t.string,
    conditions: topLevelConditionConverter,
    priority: t.optional(t.number),
    event: t.strict({
      type: t.string,
      params: t.optional(t.record(t.unknown))
    }),
    enabled: t.boolean.default(() => true)
  })
);

export const fetchPricingRulesApi = async (): Promise<PricingRuleResult> => {
  const response = await loggedInFetch('/api/pricing-rules');
  if (response.ok) {
    const json = await response.json();
    return pricingRulesConverter(json);
  }

  throw new Error(
    `Error fetching pricing rules, status: ${response.status}, text: ${response.statusText}`
  );
};

export interface UpdatePricingRulesPayload {
  rules?: Record<string, Omit<RuleProperties, 'onSuccess' | 'onFailure'>>;
}

export const updatePricingRulesApi = async (payload: UpdatePricingRulesPayload) => {
  const response = await loggedInFetch('/api/pricing-rules', {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error(
      `Error updating pricing rules: ${response.status}, text: ${response.statusText}`
    );
  }
};

export const deletePricingRulesApi = async (payload: { rules: string[] }) => {
  const response = await loggedInFetch('/api/pricing-rules', {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error(
      `Error deleting pricing rules: ${response.status}, text: ${response.statusText}`
    );
  }
};
