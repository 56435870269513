import { OwnUpFillButtonSecondary, OwnUpIconButton } from '@rategravity/own-up-component-library';
import { CopyIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/copy';
import { EditIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/edit';
import React from 'react';
import styled from 'styled-components';
import { BaseRule } from '../../modules/rules/base-rule';
import { formatEventProps } from '../../modules/rules/format-rules';
import { ConditionSummary, EventWithScore } from '../lender-configuration/condition-summary';

type RuleListProps = {
  data: (BaseRule & { ruleId: string } & EventWithScore)[];
  onEdit: (ruleId: string) => void;
  onClone: (rule: BaseRule) => void;
  onDelete: (ruleId: string) => void;
};

const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledTable = styled.table`
  width: auto;
  td {
    white-space: nowrap;
    text-align: left;
    padding: 0 8px;
  }
  td:last-child {
    width: 100%;
  }
`;

/**
 * Component to display a list of rules (can be any type of rule).
 * Each rule can be clicked on to take the user to another page to edit that rule.
 */
export const RuleList = ({ data, onEdit, onClone, onDelete }: RuleListProps) => {
  const hasScore = data[0] && data[0].event?.params?.score;
  if (hasScore) {
    data.sort((a, b) => (b.event?.params?.score ?? 0) - (a.event?.params?.score ?? 0));
  }
  return (
    <ListWrapper>
      <StyledTable>
        <thead>
          {data[0] && 'ruleSets' in data[0] && <th>Rule set(s)</th>}
          {data[0] && data[0].event?.params?.score && <th>Score</th>}
          <th>Enabled?</th>
          <th>Rule</th>
        </thead>
        <tbody>
          {data.map((rule) => (
            <ConditionSummary
              key={rule.ruleId}
              name={rule.name}
              condition={rule}
              enabled={rule.enabled}
              showAsTable={true}
            >
              <div>{`Emit ${formatEventProps(rule.event)}`}</div>
              <OwnUpIconButton icon={EditIcon} name="Edit" onClick={() => onEdit(rule.ruleId)} />
              <OwnUpIconButton icon={CopyIcon} name="Clone" onClick={() => onClone(rule)} />
              <OwnUpFillButtonSecondary onClick={() => onDelete(rule.ruleId)}>
                Delete
              </OwnUpFillButtonSecondary>
            </ConditionSummary>
          ))}
        </tbody>
      </StyledTable>
    </ListWrapper>
  );
};
