import { BaseRule } from '../../modules/rules/base-rule';

/**
 * LenderCondition is a condition of a rule that has been extracted
 * as a standalone object so it can be reused by multiple rules.
 *
 * It is similar to a rule in that it has a name and set of conditions, and the UI can edit it.
 * But it is not a full "rule" and it does not get evaluated by itself, so it does not emit an event.
 */
export interface LenderCondition extends Pick<BaseRule, 'status' | 'name' | 'conditions'> {}

/**
 * LenderRule extends BaseRule and adds the ruleSets property.
 */
export interface LenderRule extends BaseRule {
  ruleSets: string[];
}

export interface LenderState {
  loading: boolean;
  error: boolean;
  savingRules: boolean;
  rules: Record<string, LenderRule>;
  conditions: Record<string, LenderCondition>;
}

export type LenderRulesState = Record<string, LenderState>;

export const initialState: LenderState = {
  loading: true,
  error: false,
  savingRules: false,
  rules: {},
  conditions: {}
};
