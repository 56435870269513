import { NestedCondition } from 'json-rules-engine/types';
import * as t from 'type-shift';

const commonConditionConverters = {
  name: t.optional(t.string),
  priority: t.optional(t.number)
};

const nestedConditionConverter: t.Converter<NestedCondition> = t
  .strict({
    ...commonConditionConverters,
    fact: t.string,
    operator: t.string,
    value: t.strict({ fact: t.string, params: t.optional(t.record(t.unknown)) }).or(t.unknown),
    path: t.optional(t.string),
    params: t.optional(t.record(t.unknown)),
    priority: t.optional(t.number)
  })
  .or((input, path, entity) => topLevelConditionConverter(input, path, entity));

export const topLevelConditionConverter = t
  .strict({
    ...commonConditionConverters,
    all: t.array(nestedConditionConverter)
  })
  .or(
    t.strict({
      ...commonConditionConverters,
      any: t.array(nestedConditionConverter)
    })
  )
  .or(
    t.strict({
      ...commonConditionConverters,
      not: nestedConditionConverter
    })
  )
  .or(
    t.strict({
      ...commonConditionConverters,
      condition: t.string
    })
  );
