import {
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSmallHeadlineBook
} from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { TableContainer } from '../../components/containers/table-container';
import { RuleList } from '../../components/rules/rule-list';
import { SaveRules } from '../../components/rules/save-rules';
import {
  deletePricingRule,
  editPricingRule,
  renamePricingRule,
  savePricingRulesAction
} from '../../redux/pricing-rules/actions';
import {
  pendingSaveSelector,
  pricingRulesSelector,
  savingSelector
} from '../../redux/pricing-rules/selector';
import { AppState } from '../../redux/store';

const RulesWrapper = styled.div`
  margin: 24px 0 24px;
`;

interface PricingRulesEditorParams {
  onRuleEdit: (ruleId: string) => void;
}

const LoadedRules = ({ onRuleEdit }: PricingRulesEditorParams) => {
  const lenderRules = useCallback((state: AppState) => pricingRulesSelector(state), []);
  const rules = useSelector(lenderRules, shallowEqual);
  const dispatch = useDispatch();

  return (
    <RulesWrapper>
      <OwnUpSmallHeadlineBook variant="h2">Pricing Rules</OwnUpSmallHeadlineBook>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <RuleList
            data={rules}
            onEdit={onRuleEdit}
            onClone={({ name, conditions, event }) => {
              const id = uuid();
              dispatch(renamePricingRule(id, `${name} Copy`));
              dispatch(editPricingRule(id, { conditions, event }));
              onRuleEdit(id);
            }}
            onDelete={(ruleId) => dispatch(deletePricingRule(ruleId))}
          />
          <OwnUpFillButtonPrimary onClick={() => onRuleEdit(uuid())}>
            New Rule
          </OwnUpFillButtonPrimary>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </RulesWrapper>
  );
};

export const PricingRulesPage = ({ onRuleEdit }: PricingRulesEditorParams) => {
  const dispatch = useDispatch();
  const pendingSave = useSelector((state: AppState) => pendingSaveSelector(state));
  const activelySaving = useSelector((state: AppState) => savingSelector(state));

  const onSave = useCallback(() => {
    dispatch(savePricingRulesAction());
  }, [dispatch]);

  // This hooks into the beforeunload event and prompts the user to confirm leaving the page
  //  if there is a pendingSave
  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      if (pendingSave) {
        event.preventDefault();
        event.returnValue =
          'Rules have been edited without being saved, are you sure you want to exit?';
      }
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [pendingSave]);

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            Pricing Rules
          </OwnUpHeadlineBook>
          <TableContainer>
            <LoadedRules onRuleEdit={onRuleEdit} />
            <SaveRules pendingSave={pendingSave} activelySaving={activelySaving} onSave={onSave} />
          </TableContainer>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
