import {
  OwnUpDefaultInfoNotification,
  OwnUpFillButtonPrimary,
  OwnUpSpinner
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const SaveContainer = styled.div`
  display: flex;
  gap: 16px;
`;

interface SaveRulesProps {
  pendingSave: boolean;
  activelySaving: boolean;
  onSave: () => void;
}

/**
 * Component to display the save button and save status
 */
export const SaveRules = ({ pendingSave, activelySaving, onSave }: SaveRulesProps) => (
  <SaveContainer>
    <OwnUpFillButtonPrimary disabled={!pendingSave || activelySaving} onClick={onSave}>
      Save
    </OwnUpFillButtonPrimary>
    {activelySaving && (
      <div>
        Saving ...
        <OwnUpSpinner />
      </div>
    )}
    {pendingSave && (
      <OwnUpDefaultInfoNotification description="Edits have been made but not saved" />
    )}
  </SaveContainer>
);
