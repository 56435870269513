import { USStates } from '@rategravity/core-lib/enums';
import type { RJSFSchema } from '@rjsf/utils';

export const schema: RJSFSchema = {
  definitions: {
    internalLeadSchema: {
      type: 'object',
      properties: {
        // These are not actually part of the unified lead schema but can drive
        //   partner channel logic
        comments: {
          title: 'customFields/comments',
          type: 'string'
        },
        leadType: {
          title: 'internal/leadType',
          enum: ['web-lead', 'direct-web-lead', 'preapproval-lead', 'click-out-lead']
        }
      }
    },
    baseUnifiedLead: {
      type: 'object',
      allOf: [{ $ref: '#/definitions/internalLeadSchema' }],
      required: [
        'source',
        'sourceId',
        'leadID',
        'leadCost',
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'creditRating',
        'vaEligible',
        'propertyState',
        'propertyValue',
        'propertyType',
        'propertyUse',
        'loanAmount'
      ],
      properties: {
        // -- Lead tracking information
        /* Where the lead came from (e.g., `rdc`, `Own Up`*/
        source: { type: 'string' },
        /* The unique id for this lead, per the source */
        sourceId: { type: 'string' },
        /* Unique Id for this lead */
        leadID: { type: 'string' },
        leadCost: { type: 'number' },

        firstName: { type: 'string' },
        lastName: { type: 'string' },
        email: { type: 'string', format: 'email' },
        phoneNumber: { type: 'string' },
        creditRating: { enum: ['Excellent', 'Good', 'Fair', 'Poor'] },
        vaEligible: { type: 'boolean' },

        // -- Property information
        propertyStreet: { type: 'string' },
        propertyCity: { type: 'string' },
        propertyState: { enum: USStates },
        propertyCounty: { type: 'string' },
        propertyZip: { type: 'string' },
        /** How much the property is worth */
        propertyValue: { type: 'number' },
        /** Type of property in question */
        propertyType: {
          enum: [
            'SingleFamily' as const,
            'MultiFamily' as const,
            'Condo' as const,
            'Manufactured' as const,
            'Townhome' as const,
            'NewConstruction' as const
          ]
        },
        /** How the property is being used */
        propertyUse: { enum: ['Primary' as const, 'Secondary' as const, 'Investment' as const] },

        // -- Loan information
        /** Size of the loan */
        loanAmount: { type: 'number' },

        // -- Universal Lead identification
        jornayaLeadId: { type: 'string' },
        trustedFormCertificateUrl: { type: 'string' }
      }
    },
    baseUnifiedPurchaseLeadSchema: {
      type: 'object',
      properties: {
        hasAcceptedOffer: {
          title: 'hasAcceptedOffer',
          type: 'boolean'
        }
      }
    },
    purchaseLead: {
      title: 'Purchase Unified Lead Schema',
      allOf: [
        { $ref: '#/definitions/baseUnifiedLead' },
        { $ref: '#/definitions/baseUnifiedPurchaseLeadSchema' }
      ],
      required: ['loanPurpose'],
      properties: {
        loanPurpose: { const: 'Purchase' },
        fthb: { type: 'boolean' }
      }
    },
    refinanceLead: {
      title: 'Refinance Unified Lead Schema',
      allOf: [{ $ref: '#/definitions/baseUnifiedLead' }],
      required: ['loanPurpose', 'cashOutAmount', 'currentMortgageBalance'],
      properties: {
        loanPurpose: { const: 'Refinance' },
        cashOutAmount: { type: 'number', default: 0 },
        currentMortgageBalance: { type: 'number' },
        currentMortgageRate: { type: 'number' } // decimal representation
      }
    },
    processLeadPayload: {
      type: 'object',
      required: ['target', 'lead', 'campaign'],
      properties: {
        target: { type: 'string', description: 'The ID of the lender to send the lead to' },
        campaign: {
          title: 'Campaign Name',
          type: 'string'
        },
        lead: {
          title: 'Unified Lead Schema',
          oneOf: [{ $ref: '#/definitions/purchaseLead' }, { $ref: '#/definitions/refinanceLead' }]
        }
      }
    }
  },
  title: 'Send Test Lead',
  type: 'object',
  required: ['environment'],
  allOf: [{ $ref: '#/definitions/processLeadPayload' }],
  properties: {
    environment: { enum: ['prod', 'staging'] }
  }
};
