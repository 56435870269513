import {
  OwnUpBodyMedium,
  OwnUpCheckbox,
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConditionEditor } from '../../components/condition-editor';
import { EventEditor } from '../../components/event-editor';
import { facts, lenderEndpointRules, lenderEndpointRulesFacts } from '../../modules/rules/schema';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import {
  editLenderEndpointRule,
  renameLenderEndpointRule
} from '../../redux/lender-endpoint-rules/actions';
import { lenderEndpointRuleSelector } from '../../redux/lender-endpoint-rules/selector';
import { lenderConditionsSelector } from '../../redux/lender-rules/selector';
import { AppState } from '../../redux/store';

interface LenderEndpointRuleEditorParams {
  lenderId: string;
  ruleId: string;
  onDone: () => void;
}

/**
 * Editor page for a specific lender's endpoint rule.
 */
export const LenderEndpointRuleEditorPage = ({
  lenderId,
  ruleId,
  onDone
}: LenderEndpointRuleEditorParams) => {
  const dispatch = useDispatch();

  const rule = useSelector((state: AppState) =>
    lenderEndpointRuleSelector(state, { lenderId, ruleId })
  ) ?? {
    name: '',
    conditions: { all: [] },
    ruleSets: []
  };
  // These are the global conditions under the "default" id
  const sharedConditions = useSelector((state: AppState) =>
    lenderConditionsSelector(state, { lenderId: DEFAULT_LENDER_ID })
  ).map(({ name }) => name);

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            {`Lender Endpoint Rule Editor: ${rule.name}`}
          </OwnUpHeadlineBook>
          <div>
            <OwnUpTextInput
              label="Name"
              value={rule.name}
              onChange={(event) =>
                dispatch(renameLenderEndpointRule(lenderId, ruleId, event.target.value))
              }
            />
            <OwnUpCheckbox
              checked={rule.enabled}
              onChange={(event) => {
                dispatch(
                  editLenderEndpointRule(lenderId, ruleId, {
                    event: rule.event,
                    conditions: rule.conditions,
                    enabled: event.target.checked
                  })
                );
              }}
            >
              Enabled
            </OwnUpCheckbox>
            <br />
            <ConditionEditor
              facts={[...facts, ...lenderEndpointRulesFacts]}
              conditions={sharedConditions}
              condition={rule.conditions}
              validationErrors={new Map()}
              onConditionUpdate={(update) =>
                dispatch(
                  editLenderEndpointRule(lenderId, ruleId, {
                    event: rule.event,
                    conditions: update
                  })
                )
              }
            />
            <br />
            <OwnUpBodyMedium variant="body1">Emit Event</OwnUpBodyMedium>
            <EventEditor
              factOptions={facts}
              ruleOptions={lenderEndpointRules}
              event={rule.event}
              onEventUpdate={(event) =>
                dispatch(
                  editLenderEndpointRule(lenderId, ruleId, { conditions: rule.conditions, event })
                )
              }
            />
            <OwnUpFillButtonPrimary onClick={onDone}>Done</OwnUpFillButtonPrimary>
          </div>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
