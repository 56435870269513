import type { RuleProperties } from 'json-rules-engine';

export interface PricingRule extends Omit<RuleProperties, 'onSuccess' | 'onFailure'> {
  status: 'clean' | 'edited' | 'deleted';
  name: string;
  enabled: boolean;
}

export interface PricingRulesState {
  loading: boolean;
  error: boolean;
  savingRules: boolean;
  rules: Record<string, PricingRule>;
}

export const initialState: PricingRulesState = {
  loading: true,
  error: false,
  savingRules: false,
  rules: {}
};
