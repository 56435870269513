import {
  IconLink,
  OwnUpBody,
  OwnUpBodyMedium,
  OwnUpComponentLibraryProvider,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpSlimButtonPrimary,
  OwnUpSpinner,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import { loadLenderConfigAction } from '../../redux/lender-configuration/actions';
import {
  lenderBudgetsLoading,
  lenderBudgetsSelector
} from '../../redux/lender-configuration/selector';
import { loadLenderEndpointRulesAction } from '../../redux/lender-endpoint-rules/actions';
import { lenderEndpointRulesLoadingSelector } from '../../redux/lender-endpoint-rules/selector';
import { loadLenderRulesAction } from '../../redux/lender-rules/actions';
import { AppState } from '../../redux/store';
import { LenderEndpointRuleEditorPage } from './lender-endpoint-rule-editor';
import { LenderEndpointRulesPage } from './lender-endpoint-rules';

/**
 * Entry point for specific lender's endpoint rules.
 * Base route shows the summary of a lenders endpoint rules.
 * Clicking on a rule will take you to the editor for that rule.
 */
export const LenderSpecificConfiguration = ({
  basePath,
  lenderId
}: {
  basePath: string;
  lenderId: string;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLenderEndpointRulesAction(lenderId));
    dispatch(loadLenderRulesAction(DEFAULT_LENDER_ID));
  }, [dispatch, lenderId]);

  const lenderEndpointRulesLoading = useSelector((state: AppState) =>
    lenderEndpointRulesLoadingSelector(state, lenderId)
  );

  if (lenderEndpointRulesLoading) {
    return <OwnUpSpinner />;
  }
  return (
    <Switch>
      <Route
        path={`${basePath}/editor/rule/:ruleId`}
        render={({ match }) => (
          <LenderEndpointRuleEditorPage
            lenderId={lenderId}
            ruleId={match.params.ruleId}
            onDone={() => {
              history.push(`${basePath}`);
            }}
          />
        )}
      />
      <Route path={basePath}>
        <LenderEndpointRulesPage
          lenderId={lenderId}
          onRuleEdit={(ruleId) => history.push(`${basePath}/editor/rule/${ruleId}`)}
        />
      </Route>
    </Switch>
  );
};

const LenderContainer = styled.div`
  margin: 0 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const NewLenderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const LenderList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadLenderConfigAction());
  }, [dispatch]);

  const loading = useSelector(lenderBudgetsLoading);
  const lenders = useSelector(lenderBudgetsSelector);
  const [newLender, setNewLender] = useState('');
  if (loading) {
    return <OwnUpSpinner />;
  }
  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            Lender Endpoint Rules
          </OwnUpHeadlineBook>
          <OwnUpBodyMedium variant="body1">
            Click on a lender to view their endpoint rules. Or add an endpoint for a new lender.
            <LenderContainer>
              <NewLenderContainer>
                <OwnUpTextInput
                  value={newLender}
                  label="lenderId"
                  onChange={(event) => setNewLender(event.target.value)}
                />
                <OwnUpSlimButtonPrimary
                  onClick={() => {
                    history.push(`/lender-endpoint-rules/${newLender}`);
                  }}
                >
                  Create new lender endpoints
                </OwnUpSlimButtonPrimary>
              </NewLenderContainer>
              {Object.keys(lenders).map((lenderId, index) => (
                <OwnUpBody key={index} variant="body1">
                  <IconLink icon={<></>} link={`/lender-endpoint-rules/${lenderId}`}>
                    {lenderId}
                  </IconLink>
                </OwnUpBody>
              ))}
            </LenderContainer>
          </OwnUpBodyMedium>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};

/**
 * Main entry point for the lender endpoint rules app.
 */
export const LenderEndpointRulesApp = () => {
  const routeMatch = useRouteMatch();
  const basePath = routeMatch.path;
  return (
    <OwnUpComponentLibraryProvider>
      <Switch>
        <Route
          path={`${basePath}/:lenderId`}
          render={({ match }) => (
            <LenderSpecificConfiguration
              basePath={`${basePath}/${match.params.lenderId}`}
              lenderId={match.params.lenderId}
            />
          )}
        />
        <Route path={basePath}>
          <LenderList />
        </Route>
      </Switch>
    </OwnUpComponentLibraryProvider>
  );
};
