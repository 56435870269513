import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  deleteLenderEndpointRulesApi,
  DeleteLenderEndpointRulesPayload,
  fetchLenderEndpointRulesApi,
  LenderEndpointRuleResult,
  updateLenderEndpointRulesApi,
  UpdateLenderEndpointRulesPayload
} from '../../modules/api/lender-endpoint-rules';
import {
  lenderEndpointRulesLoadedAction,
  LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  LoadLenderEndpointRulesAction,
  loadLenderEndpointRulesFailedAction,
  SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  savedLenderEndpointRulesAction,
  SaveLenderEndpointRulesAction
} from './actions';
import { deletePayloadSelector, updatePayloadSelector } from './selector';

function* fetchLenderEndpointRules({ payload: { lenderId } }: LoadLenderEndpointRulesAction) {
  try {
    const result: LenderEndpointRuleResult = yield call(fetchLenderEndpointRulesApi, lenderId);
    yield put(lenderEndpointRulesLoadedAction({ lenderId, rules: result }));
  } catch (err) {
    console.log('Error loading lender endpoint rules', err);
    yield put(loadLenderEndpointRulesFailedAction(lenderId));
  }
}

function* saveLenderEndpointRules({ payload: { lenderId } }: SaveLenderEndpointRulesAction) {
  try {
    const updatePayload: UpdateLenderEndpointRulesPayload = yield select(
      updatePayloadSelector,
      lenderId
    );
    const deletePayload: DeleteLenderEndpointRulesPayload = yield select(
      deletePayloadSelector,
      lenderId
    );
    yield all([
      call(updateLenderEndpointRulesApi, updatePayload),
      call(deleteLenderEndpointRulesApi, deletePayload)
    ]);
    yield put(savedLenderEndpointRulesAction(lenderId));
  } catch (err) {
    console.error('Error updating lender endpoint rules', err);
  }
}

export function* lenderEndpointRulesSaga() {
  yield all([takeEvery([LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE], fetchLenderEndpointRules)]);
  yield all([takeEvery([SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE], saveLenderEndpointRules)]);
}
