import { useInternalAuth } from '@rategravity/widgets-react/modules/auth/internal/hooks/use-internal-auth';
import React, { useEffect } from 'react';
import { AuthHeader } from '../../components/auth-header';
import { UnauthHeader } from '../../components/unauth-header';
import { isValidRedirect } from './is-valid-redirect';

export const Auth = () => {
  const { isLoggedIn, login } = useInternalAuth();
  useEffect(() => {
    if (!isLoggedIn) {
      void login(window.location.origin);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      let redirect = urlParams.get('redirect');
      // If no valid redirect is passed, default to '/'
      if (!isValidRedirect(redirect)) {
        redirect = '/';
      }
      // Set the path name to the redirect path
      window.location.assign(redirect as string);
    }
  }, [isLoggedIn, login]);
  return <AuthHeader />;
};

export const Unauthorized = () => {
  // Default redirect path
  let redirectPath = '/';
  // Try to get the redirect path from the referrer
  if (document.referrer) {
    try {
      redirectPath = new URL(document.referrer).pathname;
    } catch {
      // ... if we couldn't generate the redirectPath, just use the default.
    }
  }
  const { login } = useInternalAuth();
  // Try logging the user in again
  useEffect(() => {
    void login(window.location.origin + '/auth?redirect=' + redirectPath);
  }, [login, redirectPath]);

  return <UnauthHeader login={login} redirectPath={`?redirect=${redirectPath}`} />;
};
