import {
  OwnUpBodyMedium,
  OwnUpCheckbox,
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConditionEditor } from '../../components/condition-editor';
import { EventEditor } from '../../components/event-editor';
import { facts, pricingRules, pricingRulesFacts } from '../../modules/rules/schema';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import { loadBudgetsAction } from '../../redux/lender-configuration/actions';
import { lenderConditionsSelector } from '../../redux/lender-rules/selector';
import { editPricingRule, renamePricingRule } from '../../redux/pricing-rules/actions';
import { pricingRuleSelector } from '../../redux/pricing-rules/selector';
import { AppState } from '../../redux/store';

interface PricingRuleEditorParams {
  ruleId: string;
  onDone: () => void;
}

export const PricingRuleEditorPage = ({ ruleId, onDone }: PricingRuleEditorParams) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadBudgetsAction());
  }, [dispatch]);

  const rule = useSelector((state: AppState) => pricingRuleSelector(state, { ruleId })) ?? {
    name: '',
    conditions: { all: [] },
    ruleSets: []
  };
  // These are the global conditions under the "default" id
  const sharedConditions = useSelector((state: AppState) =>
    lenderConditionsSelector(state, { lenderId: DEFAULT_LENDER_ID })
  ).map(({ name }) => name);

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            {`Pricing Rule Editor: ${rule.name}`}
          </OwnUpHeadlineBook>
          <div>
            <OwnUpTextInput
              label="Name"
              value={rule.name}
              onChange={(event) => dispatch(renamePricingRule(ruleId, event.target.value))}
            />
            <OwnUpCheckbox
              checked={rule.enabled}
              onChange={(event) => {
                dispatch(
                  editPricingRule(ruleId, {
                    event: rule.event,
                    conditions: rule.conditions,
                    enabled: event.target.checked
                  })
                );
              }}
            >
              Enabled
            </OwnUpCheckbox>
            <br />
            <ConditionEditor
              facts={[...facts, ...pricingRulesFacts]}
              conditions={sharedConditions}
              condition={rule.conditions}
              validationErrors={new Map()}
              onConditionUpdate={(update) =>
                dispatch(editPricingRule(ruleId, { event: rule.event, conditions: update }))
              }
            />
            <br />
            <OwnUpBodyMedium variant="body1">Emit Event</OwnUpBodyMedium>
            <EventEditor
              factOptions={facts}
              ruleOptions={pricingRules}
              event={rule.event}
              onEventUpdate={(event) =>
                dispatch(editPricingRule(ruleId, { conditions: rule.conditions, event }))
              }
            />
            <OwnUpFillButtonPrimary onClick={onDone}>Done</OwnUpFillButtonPrimary>
          </div>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
