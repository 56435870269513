import { OwnUpFilterDropdown } from '@rategravity/own-up-component-library';
import { NestedCondition } from 'json-rules-engine';
import React from 'react';
import styled from 'styled-components';
import { getOperatorsForFact } from '../../modules/rules/operators';
import { FactInfo } from '../../modules/rules/schema';
import { MaybeTooltip } from '../tooltips/maybe-tooltip';
import { BooleanEditor } from './boolean-editor';
import { ConditionReferenceEditor } from './condition-reference-editor';
import { FactEditor } from './fact-editor';

type ComparisonEditorProps = {
  path: string;
  condition: NestedCondition | undefined;
  facts: FactInfo[];
  conditions: string[];
  validationErrors: Map<string, string>;
  onConditionUpdate: (comparison: NestedCondition) => void;
  removeButton: React.ReactNode;
};

const getSelection = (
  comparison: NestedCondition | undefined
):
  | { type: undefined; value: undefined }
  | { type: 'condition'; value: string }
  | { type: 'fact'; value: string }
  | { type: 'boolean'; value: 'all' | 'any' } => {
  if (comparison === undefined) {
    return { type: undefined, value: undefined };
  } else if ('condition' in comparison) {
    return { type: 'condition', value: comparison.condition };
  } else if ('fact' in comparison) {
    // On the backend, nested facts are stored with the base object as `fact`, and the nested object as `path`
    // Translate { fact: 'base', path: 'nested.field' } to a factId of 'base.nested.field'
    return {
      type: 'fact',
      value: comparison.path ? `${comparison.fact}.${comparison.path}` : comparison.fact
    };
  } else if ('not' in comparison) {
    return getSelection(comparison.not);
  } else if ('any' in comparison) {
    return { type: 'boolean', value: 'any' };
  } else {
    return { type: 'boolean', value: 'all' };
  }
};

const getDescription = (value: string | undefined, type: string | undefined, facts: FactInfo[]) => {
  switch (type) {
    case 'fact':
      return facts.find(({ factId }) => factId === value)?.description ?? '';
    case 'condition':
      return 'Reference to a shared condition.';
    case 'boolean':
      return value === 'any'
        ? 'This block will pass if ANY condition is met.'
        : 'This block will pass if ALL conditions are met.';
    default:
      return '';
  }
};

const DropDownContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

const EmptyCondtionContainer = styled.div`
  grid-column: span 5;
`;

/**
 * Editor for a comparison. Renders an Autocomplete Dropdown
 * for selecting what the comparison should be then
 * renders the appropriate editor for that comparison type.
 */
export const ComparisonEditor = ({
  path,
  condition,
  facts,
  conditions,
  validationErrors,
  onConditionUpdate,
  removeButton
}: ComparisonEditorProps) => {
  const { type, value } = getSelection(condition);
  const description = getDescription(value, type, facts);
  const conditionSelect = (
    <MaybeTooltip description={description}>
      <DropDownContainer>
        <OwnUpFilterDropdown
          value={value}
          onChange={(_event: unknown, val: string) => {
            const booleanChildren =
              type === 'boolean'
                ? ((condition as unknown as Record<string, NestedCondition[]>)[value as string] ??
                  [])
                : [];
            if (val === 'all') {
              onConditionUpdate({ all: booleanChildren });
            } else if (val === 'any') {
              onConditionUpdate({ any: booleanChildren });
            } else if (conditions.some((c) => c === val)) {
              onConditionUpdate({ condition: val as string });
            } else {
              onConditionUpdate({ fact: val as string, operator: '', value: undefined });
            }
          }}
          options={[
            'all',
            'any',
            ...facts.map(({ factId }) => factId).sort(),
            ...conditions.map((cond) => cond).sort()
          ]}
        />
      </DropDownContainer>
    </MaybeTooltip>
  );
  switch (type) {
    case 'boolean':
      return (
        <BooleanEditor
          path={`${path}/${value}`}
          canCollapse={true}
          facts={facts}
          conditions={conditions}
          validationErrors={validationErrors}
          conditionSelect={conditionSelect}
          removeButton={removeButton}
          condition={condition as any}
          onConditionUpdate={onConditionUpdate}
        />
      );
    case 'condition':
      return (
        <ConditionReferenceEditor
          path={path}
          validationErrors={validationErrors}
          conditionSelect={conditionSelect}
          removeButton={removeButton}
          condition={condition as any}
          onConditionUpdate={onConditionUpdate}
        />
      );
    case 'fact':
      return (
        <FactEditor
          path={path}
          factId={value}
          validationErrors={validationErrors}
          conditionSelect={conditionSelect}
          removeButton={removeButton}
          condition={condition as any}
          onConditionUpdate={onConditionUpdate}
          allFacts={facts}
          operatorOptions={getOperatorsForFact(facts.find(({ factId }) => factId === value))}
        />
      );
    case undefined:
    default:
      return <EmptyCondtionContainer>{conditionSelect}</EmptyCondtionContainer>;
  }
};
