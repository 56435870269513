export const RULE_SETS = [
  'web-leads',
  'direct-web-lead',
  'preapproval-lead',
  'rate-quote-lead',
  'click-out-lead',
  'rate-table-lead',
  'recapture-from-concierge-lead'
];

export const DEFAULT_LENDER_ID = 'default';
