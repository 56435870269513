import { RuleProperties, TopLevelCondition } from 'json-rules-engine';
import * as t from 'type-shift';
import { LenderRule } from '../../redux/lender-rules/state';
import { loggedInFetch } from '../authentication';
import { topLevelConditionConverter } from './types';

export interface LenderRuleResult {
  rules: Omit<LenderRule, 'status'>[];
  conditions: { name: string; conditions: TopLevelCondition }[];
}

const lenderRulesConverter = t.strict<LenderRuleResult>({
  rules: t.forPath(
    [t.CurrentPath, 'result'],
    t.array(
      t.strict({
        name: t.string,
        conditions: topLevelConditionConverter,
        priority: t.optional(t.number),
        event: t.strict({
          type: t.string,
          params: t.optional(t.record(t.unknown))
        }),
        ruleSets: t.array(t.string),
        enabled: t.boolean.default(() => true)
      })
    )
  ),
  conditions: t.forPath(
    [t.CurrentPath, 'result'],
    t.array(
      t.strict({
        name: t.string,
        conditions: topLevelConditionConverter
      })
    )
  )
});

export const fetchLenderConditionsApi = async (lenderId: string): Promise<LenderRuleResult> => {
  const response = await loggedInFetch(
    `/api/lender/configuration/rules/${lenderId}?include[0]=conditions&include[1]=rules`
  );
  if (response.ok) {
    const json = await response.json();
    return lenderRulesConverter(json);
  }

  throw new Error(
    `Error fetching lender rules, status: ${response.status}, text: ${response.statusText}`
  );
};

export interface UpdateLenderRulesPayload {
  lenderId: string;
  rules?: Record<string, Omit<RuleProperties, 'name' | 'onSuccess' | 'onFailure'>>;
  conditions?: Record<string, TopLevelCondition>;
}

export const updateLenderRulesApi = async (payload: UpdateLenderRulesPayload) => {
  const response = await loggedInFetch(`/api/lender/configuration/rules/${payload.lenderId}`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error(
      `Error updating lender rules: ${response.status}, text: ${response.statusText}`
    );
  }
};

export const deleteLenderRulesApi = async (payload: {
  lenderId: string;
  rules?: string[];
  conditions?: string[];
}) => {
  const response = await loggedInFetch(`/api/lender/configuration/rules/${payload.lenderId}`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error(
      `Error updating lender rules: ${response.status}, text: ${response.statusText}`
    );
  }
};
