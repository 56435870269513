import { BaseRule } from '../../modules/rules/base-rule';

export interface LenderEndpointRule extends BaseRule {}

export interface LenderState {
  loading: boolean;
  error: boolean;
  savingRules: boolean;
  rules: Record<string, LenderEndpointRule>;
}

export const initialState: LenderState = {
  loading: true,
  error: false,
  savingRules: false,
  rules: {}
};

export type LenderEndpointRulesState = Record<string, LenderState>;
