import { LenderEndpointRule } from './state';

export const LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE = 'LOAD_LENDER_ENDPOINT_RULES' as const;
export const loadLenderEndpointRulesAction = (lenderId: string) => ({
  type: LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  payload: { lenderId }
});
export type LoadLenderEndpointRulesAction = ReturnType<typeof loadLenderEndpointRulesAction>;

export const LENDER_ENDPOINT_RULES_LOADED_ACTION_TYPE = 'LENDER_ENDPOINT_RULES_LOADED' as const;
export const lenderEndpointRulesLoadedAction = ({
  lenderId,
  rules
}: {
  lenderId: string;
  rules: Omit<LenderEndpointRule, 'status'>[];
}) => ({
  type: LENDER_ENDPOINT_RULES_LOADED_ACTION_TYPE,
  payload: {
    lenderId,
    rules
  }
});
export type LenderEndpointRulesLoadedAction = ReturnType<typeof lenderEndpointRulesLoadedAction>;

export const LOAD_LENDER_ENDPOINT_RULES_FAILED_ACTION_TYPE = 'LOAD_LENDER_ENDPOINT_RULES_FAILED';
export const loadLenderEndpointRulesFailedAction = (lenderId: string) => ({
  type: LOAD_LENDER_ENDPOINT_RULES_FAILED_ACTION_TYPE,
  payload: {
    lenderId
  }
});

export const EDIT_LENDER_ENDPOINT_RULE_ACTION_TYPE = 'EDIT_LENDER_ENDPOINT_RULE' as const;
export const editLenderEndpointRule = (
  lenderId: string,
  ruleId: string,
  ruleProperties: Partial<Pick<LenderEndpointRule, 'conditions' | 'event' | 'enabled'>>
) => ({
  type: EDIT_LENDER_ENDPOINT_RULE_ACTION_TYPE,
  payload: {
    lenderId,
    ruleId,
    ruleProperties
  }
});
export type EditLenderEndpointRuleAction = ReturnType<typeof editLenderEndpointRule>;

export const RENAME_LENDER_ENDPOINT_RULE_ACTION_TYPE = 'RENAME_LENDER_ENDPOINT_RULE' as const;
export const renameLenderEndpointRule = (lenderId: string, ruleId: string, name: string) => ({
  type: RENAME_LENDER_ENDPOINT_RULE_ACTION_TYPE,
  payload: {
    lenderId,
    ruleId,
    name
  }
});
export type RenameLenderEndpointRuleAction = ReturnType<typeof renameLenderEndpointRule>;

export const DELETE_LENDER_ENDPOINT_RULE_ACTION_TYPE = 'DELETE_LENDER_ENDPOINT_RULE' as const;
export const deleteLenderEndpointRule = (lenderId: string, ruleId: string) => ({
  type: DELETE_LENDER_ENDPOINT_RULE_ACTION_TYPE,
  payload: {
    lenderId,
    ruleId
  }
});
export type DeleteLenderEndpointRuleAction = ReturnType<typeof deleteLenderEndpointRule>;

export const SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE = 'SAVE_LENDER_ENDPOINT_RULES';
export const saveLenderEndpointRulesAction = (lenderId: string) => ({
  type: SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  payload: {
    lenderId
  }
});
export type SaveLenderEndpointRulesAction = ReturnType<typeof saveLenderEndpointRulesAction>;

export const SAVED_LENDER_ENDPOINT_RULES_ACTION_TYPE = 'SAVED_LENDER_ENDPOINT_RULES';
export const savedLenderEndpointRulesAction = (lenderId: string) => ({
  type: SAVED_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  payload: {
    lenderId
  }
});
export type SavedLenderEndpointRulesAction = ReturnType<typeof savedLenderEndpointRulesAction>;
