/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { OwnUpFillButtonSecondary, OwnUpIconButton } from '@rategravity/own-up-component-library';
import { EditIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/edit';
import React from 'react';
import styled from 'styled-components';
import { LenderState } from '../../redux/lender-rules/state';
import { ConditionSummary } from './condition-summary';

const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

type LenderConditionsListProps = {
  data: (LenderState['conditions'][string] & { conditionId: string })[];
  inUseConditions: string[];
  onEdit?: (conditionId: string) => void;
  onDelete: (conditionId: string) => void;
};

/**
 * Shows a list of the lender's conditions
 *  (the conditions shared between this lender's rules)
 */
export const LenderConditionsList = ({
  data,
  inUseConditions,
  onEdit,
  onDelete
}: LenderConditionsListProps) => {
  return (
    <ListWrapper>
      {data.map((condition) => (
        <ConditionSummary
          key={condition.conditionId}
          name={condition.name}
          condition={condition}
          enabled={true}
          showAsTable={false}
        >
          {onEdit && (
            <OwnUpIconButton
              icon={EditIcon}
              name="Edit"
              onClick={() => onEdit(condition.conditionId)}
            />
          )}
          <OwnUpFillButtonSecondary
            disabled={inUseConditions.includes(condition.conditionId)}
            onClick={() => onDelete(condition.conditionId)}
          >
            Delete
          </OwnUpFillButtonSecondary>
        </ConditionSummary>
      ))}
    </ListWrapper>
  );
};
