import { FactInfo, RuleInfo } from './types';

export const lenderEndpointRules: RuleInfo[] = [
  {
    type: 'lenderEndpointRule',
    params: {
      testEndpoint: {
        optional: false,
        type: 'string',
        description: 'Test endpoint for the lender'
      },
      prodEndpoint: {
        optional: false,
        type: 'string',
        description: 'Prod endpoint for the lender'
      },
      score: {
        optional: false,
        type: 'number',
        description:
          'The score this match will receive. When determining which endpoint to use, the match with the highest score will be used.'
      }
    }
  }
];

export const lenderEndpointRulesFacts: FactInfo[] = [
  {
    factId: 'match.campaign',
    type: 'string',
    description: 'The campaign of the matched lender rule'
  }
];
