import { RuleProperties } from 'json-rules-engine';
import * as t from 'type-shift';
import { LenderEndpointRule } from '../../redux/lender-endpoint-rules/state';
import { loggedInFetch } from '../authentication';
import { topLevelConditionConverter } from './types';

export type LenderEndpointRuleResult = Omit<LenderEndpointRule, 'status'>[];

const lenderEndpointRulesConverter = t.array(
  t.strict<Omit<LenderEndpointRule, 'status'>>({
    name: t.string,
    conditions: topLevelConditionConverter,
    priority: t.optional(t.number),
    event: t.strict({
      type: t.string,
      params: t.optional(t.record(t.unknown))
    }),
    enabled: t.boolean.default(() => true)
  })
);

export const fetchLenderEndpointRulesApi = async (
  lenderId: string
): Promise<LenderEndpointRuleResult> => {
  const response = await loggedInFetch(`/api/lender-endpoint-rules/${lenderId}`);
  if (response.ok) {
    const json = await response.json();
    return lenderEndpointRulesConverter(json);
  }

  throw new Error(
    `Error fetching lender endpoint rules, status: ${response.status}, text: ${response.statusText}`
  );
};

export interface UpdateLenderEndpointRulesPayload {
  lenderId: string;
  rules?: Record<string, Omit<RuleProperties, 'onSuccess' | 'onFailure'>>;
}

export const updateLenderEndpointRulesApi = async ({
  lenderId,
  rules
}: UpdateLenderEndpointRulesPayload) => {
  const response = await loggedInFetch(`/api/lender-endpoint-rules/${lenderId}`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ rules })
  });
  if (!response.ok) {
    throw new Error(
      `Error updating lender endpoint rules: ${response.status}, text: ${response.statusText}`
    );
  }
};

export interface DeleteLenderEndpointRulesPayload {
  lenderId: string;
  rules: string[];
}

export const deleteLenderEndpointRulesApi = async ({
  lenderId,
  rules
}: DeleteLenderEndpointRulesPayload) => {
  const response = await loggedInFetch(`/api/lender-endpoint-rules/${lenderId}`, {
    headers: {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    body: JSON.stringify({ rules })
  });
  if (!response.ok) {
    throw new Error(
      `Error deleting lender endpoint rules: ${response.status}, text: ${response.statusText}`
    );
  }
};
