import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { LenderBudgetConfigState } from '../lender-configuration/state';
import { LenderRulesState } from '../lender-rules/state';
import { PricingRulesState } from '../pricing-rules/state';
import { rootReducer } from '../reducers';
import { RulesValidationState } from '../rules-validation/state';
import { rootSaga } from '../sagas';
import { LenderState } from './lender/types';

export interface AppState {
  activeConfig: string;
  activeStates: USStateAndTerritoriesType[];
  error: boolean;
  lenders: LenderState;
  loading: { lenders: boolean; activeStates: boolean };
  saving: boolean;
  lastModifiedTime: number | null;
  lenderBudgetConfig?: LenderBudgetConfigState;
  lenderRules: LenderRulesState;
  pricingRules: PricingRulesState;
  showUnlicensed: boolean;
  rulesValidation: RulesValidationState;
}

const sagaMiddleware = createSagaMiddleware();

// Attempt to hook up REDUX DevTools if they exist...
const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
