/**
 * Determine if the redirect path is valid (i.e. just a path part and
 *   not a full URL or anything with appended query data)
 */
export const isValidRedirect = (redirect: string | undefined | null) => {
  if (!redirect) {
    return false;
  }
  return /^\/[a-zA-Z0-9\-\/]*$/.test(redirect);
};
