import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  DELETE_LENDER_ENDPOINT_RULE_ACTION_TYPE,
  DeleteLenderEndpointRuleAction,
  EDIT_LENDER_ENDPOINT_RULE_ACTION_TYPE,
  EditLenderEndpointRuleAction,
  LENDER_ENDPOINT_RULES_LOADED_ACTION_TYPE,
  LenderEndpointRulesLoadedAction,
  LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  LOAD_LENDER_ENDPOINT_RULES_FAILED_ACTION_TYPE,
  RENAME_LENDER_ENDPOINT_RULE_ACTION_TYPE,
  RenameLenderEndpointRuleAction,
  SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE,
  SAVED_LENDER_ENDPOINT_RULES_ACTION_TYPE
} from './actions';
import { initialState, LenderEndpointRulesState, LenderState } from './state';

const lenderReducer = composeReducers(
  withDefault<LenderState>(initialState),
  ofType(LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE, () => ({
    loading: true,
    error: false,
    savingRules: false,
    rules: {}
  })),
  ofType(LOAD_LENDER_ENDPOINT_RULES_FAILED_ACTION_TYPE, () => ({
    loading: false,
    error: true,
    savingRules: false,
    rules: {}
  })),
  ofType(
    LENDER_ENDPOINT_RULES_LOADED_ACTION_TYPE,
    (_, { payload: { rules } }: LenderEndpointRulesLoadedAction) => ({
      loading: false,
      error: false,
      savingRules: false,
      rules: Object.fromEntries(rules.map((rule) => [rule.name, { ...rule, edited: false }]))
    })
  ),
  ofType(
    EDIT_LENDER_ENDPOINT_RULE_ACTION_TYPE,
    (state: LenderState, { payload }: EditLenderEndpointRuleAction) => {
      return {
        ...state,
        rules: {
          ...state.rules,
          [payload.ruleId]: {
            ...(state.rules[payload.ruleId] ?? { name: '' }),
            ...payload.ruleProperties,
            status: 'edited' as const
          }
        }
      };
    }
  ),
  ofType(
    RENAME_LENDER_ENDPOINT_RULE_ACTION_TYPE,
    (state, { payload }: RenameLenderEndpointRuleAction) => {
      const existingRule = state.rules[payload.ruleId];
      if (existingRule) {
        // Renames a rule by first
        // creating a dummy entry in redux with the old name and marking it
        // for deletion
        // and then editing the existing entry with the new name.
        // When turned into an api call this will delete the old rule
        // and create a new rule.
        return {
          ...state,
          rules: {
            [`${payload.ruleId}##RENAME_DELETE`]: {
              name: existingRule.name,
              conditions: { all: [] },
              event: { type: '' },
              status: 'deleted' as const,
              enabled: false
            },
            ...state.rules,
            [payload.ruleId]: {
              ...existingRule,
              name: payload.name,
              status: 'edited' as const,
              enabled: true
            }
          }
        };
      }
      return {
        ...state,
        rules: {
          ...state.rules,
          [payload.ruleId]: {
            conditions: { all: [] },
            event: { type: '' },
            name: payload.name,
            status: 'edited' as const,
            enabled: true
          }
        }
      };
    }
  ),
  ofType(
    DELETE_LENDER_ENDPOINT_RULE_ACTION_TYPE,
    (state, { payload }: DeleteLenderEndpointRuleAction) => {
      return {
        ...state,
        rules: {
          ...state.rules,
          [payload.ruleId]: {
            ...state.rules[payload.ruleId],
            status: 'deleted' as const
          }
        }
      };
    }
  ),
  ofType(SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE, (state) => ({
    ...state,
    savingRules: true
  })),
  ofType(SAVED_LENDER_ENDPOINT_RULES_ACTION_TYPE, (state) => {
    return {
      ...state,
      savingRules: false,
      rules: Object.fromEntries(
        Object.entries(state.rules)
          .filter(([, rule]) => rule.status !== 'deleted')
          .map(([id, rule]) => [
            id,
            {
              ...rule,
              status: 'clean' as const
            }
          ])
      )
    };
  })
);

export const lenderEndpointRulesReducer = composeReducers(
  withDefault<LenderEndpointRulesState>({}),
  ofType(
    [
      LOAD_LENDER_ENDPOINT_RULES_ACTION_TYPE,
      LOAD_LENDER_ENDPOINT_RULES_FAILED_ACTION_TYPE,
      LENDER_ENDPOINT_RULES_LOADED_ACTION_TYPE,
      EDIT_LENDER_ENDPOINT_RULE_ACTION_TYPE,
      RENAME_LENDER_ENDPOINT_RULE_ACTION_TYPE,
      DELETE_LENDER_ENDPOINT_RULE_ACTION_TYPE,
      SAVE_LENDER_ENDPOINT_RULES_ACTION_TYPE,
      SAVED_LENDER_ENDPOINT_RULES_ACTION_TYPE
    ],
    (state, action: { type: string; payload: { lenderId: string } }) => {
      const {
        payload: { lenderId }
      } = action;
      return {
        ...state,
        [lenderId]: lenderReducer(state[lenderId], action)
      };
    }
  )
);
