import {
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpHeadlineBook
} from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { TableContainer } from '../../components/containers/table-container';
import { RuleList } from '../../components/rules/rule-list';
import { SaveRules } from '../../components/rules/save-rules';
import {
  deleteLenderEndpointRule,
  editLenderEndpointRule,
  renameLenderEndpointRule,
  saveLenderEndpointRulesAction
} from '../../redux/lender-endpoint-rules/actions';
import {
  lenderEndpointRulesSelector,
  pendingSaveSelector,
  savingSelector
} from '../../redux/lender-endpoint-rules/selector';
import { AppState } from '../../redux/store';

const RulesWrapper = styled.div`
  margin: 24px 0 24px;
`;

interface LenderEndpointRulesEditorParams {
  lenderId: string;
  onRuleEdit: (ruleId: string) => void;
}

const LoadedRules = ({ lenderId, onRuleEdit }: LenderEndpointRulesEditorParams) => {
  const lenderRules = useCallback(
    (state: AppState) => lenderEndpointRulesSelector(state, { lenderId }),
    [lenderId]
  );
  const rules = useSelector(lenderRules, shallowEqual);
  const dispatch = useDispatch();

  return (
    <RulesWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <RuleList
            data={rules}
            onEdit={onRuleEdit}
            onClone={({ name, conditions, event }) => {
              const id = uuid();
              dispatch(renameLenderEndpointRule(lenderId, id, `${name} Copy`));
              dispatch(editLenderEndpointRule(lenderId, id, { conditions, event }));
              onRuleEdit(id);
            }}
            onDelete={(ruleId) => dispatch(deleteLenderEndpointRule(lenderId, ruleId))}
          />
          <OwnUpFillButtonPrimary onClick={() => onRuleEdit(uuid())}>
            New Rule
          </OwnUpFillButtonPrimary>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </RulesWrapper>
  );
};

/**
 * Page for specific lender's endpoint rules.
 * Shows summary of their rules and allows them to be edited (editing takes you to a separate page).
 */
export const LenderEndpointRulesPage = ({
  lenderId,
  onRuleEdit
}: LenderEndpointRulesEditorParams) => {
  const dispatch = useDispatch();
  const pendingSave = useSelector((state: AppState) => pendingSaveSelector(state, lenderId));
  const activelySaving = useSelector((state: AppState) => savingSelector(state, lenderId));

  const onSave = useCallback(() => {
    dispatch(saveLenderEndpointRulesAction(lenderId));
  }, [dispatch, lenderId]);

  // This hooks into the before unload event and prompts the user to confirm leaving the page
  //  if there is a pendingSave
  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      if (pendingSave) {
        event.preventDefault();
        event.returnValue =
          'Rules have been edited without being saved, are you sure you want to exit?';
      }
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [pendingSave]);

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <OwnUpHeadlineBook variant="h1" style={{ textAlign: 'center' }}>
            {lenderId} Endpoint Rules
          </OwnUpHeadlineBook>
          <TableContainer>
            <LoadedRules lenderId={lenderId} onRuleEdit={onRuleEdit} />
            <SaveRules pendingSave={pendingSave} activelySaving={activelySaving} onSave={onSave} />
          </TableContainer>
        </OwnUpGridItem>
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
