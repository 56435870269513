import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  DELETE_PRICING_RULE_ACTION_TYPE,
  DeletePricingRuleAction,
  EDIT_PRICING_RULE_ACTION_TYPE,
  EditPricingRuleAction,
  LOAD_PRICING_RULES_ACTION_TYPE,
  LOAD_PRICING_RULES_FAILED_ACTION_TYPE,
  PRICING_RULES_LOADED_ACTION_TYPE,
  PricingRulesLoadedAction,
  RENAME_PRICING_RULE_ACTION_TYPE,
  RenamePricingRuleAction,
  SAVE_PRICING_RULES_ACTION_TYPE,
  SAVED_PRICING_RULES_ACTION_TYPE
} from './actions';
import { initialState, PricingRulesState } from './state';

export const pricingRulesReducer = composeReducers(
  withDefault<PricingRulesState>(initialState),
  ofType(LOAD_PRICING_RULES_ACTION_TYPE, () => ({
    loading: true,
    error: false,
    savingRules: false,
    rules: {}
  })),
  ofType(LOAD_PRICING_RULES_FAILED_ACTION_TYPE, () => ({
    loading: false,
    error: true,
    savingRules: false,
    rules: {}
  })),
  ofType(
    PRICING_RULES_LOADED_ACTION_TYPE,
    (_, { payload: { rules } }: PricingRulesLoadedAction) => ({
      loading: false,
      error: false,
      savingRules: false,
      rules: Object.fromEntries(rules.map((rule) => [rule.name, { ...rule, edited: false }]))
    })
  ),
  ofType(
    EDIT_PRICING_RULE_ACTION_TYPE,
    (state: PricingRulesState, { payload }: EditPricingRuleAction) => {
      return {
        ...state,
        rules: {
          ...state.rules,
          [payload.ruleId]: {
            ...(state.rules[payload.ruleId] ?? { name: '' }),
            ...payload.ruleProperties,
            status: 'edited' as const
          }
        }
      };
    }
  ),
  ofType(RENAME_PRICING_RULE_ACTION_TYPE, (state, { payload }: RenamePricingRuleAction) => {
    const existingRule = state.rules[payload.ruleId];
    if (existingRule) {
      // Renames a rule by first
      // creating a dummy entry in redux with the old name and marking it
      // for deletion
      // and then editing the existing entry with the new name.
      // When turned into an api call this will delete the old rule
      // and create a new rule.
      return {
        ...state,
        rules: {
          [`${payload.ruleId}##RENAME_DELETE`]: {
            name: existingRule.name,
            conditions: { all: [] },
            event: { type: '' },
            status: 'deleted' as const,
            enabled: false
          },
          ...state.rules,
          [payload.ruleId]: {
            ...existingRule,
            name: payload.name,
            status: 'edited' as const,
            enabled: true
          }
        }
      };
    }
    return {
      ...state,
      rules: {
        ...state.rules,
        [payload.ruleId]: {
          conditions: { all: [] },
          event: { type: '' },
          name: payload.name,
          status: 'edited' as const,
          enabled: true
        }
      }
    };
  }),
  ofType(DELETE_PRICING_RULE_ACTION_TYPE, (state, { payload }: DeletePricingRuleAction) => {
    return {
      ...state,
      rules: {
        ...state.rules,
        [payload.ruleId]: {
          ...state.rules[payload.ruleId],
          status: 'deleted' as const
        }
      }
    };
  }),
  ofType(SAVE_PRICING_RULES_ACTION_TYPE, (state) => ({
    ...state,
    savingRules: true
  })),
  ofType(SAVED_PRICING_RULES_ACTION_TYPE, (state) => {
    return {
      ...state,
      savingRules: false,
      rules: Object.fromEntries(
        Object.entries(state.rules)
          .filter(([, rule]) => rule.status !== 'deleted')
          .map(([id, rule]) => [
            id,
            {
              ...rule,
              status: 'clean' as const
            }
          ])
      )
    };
  })
);
