import { createSelector } from 'reselect';
import { UpdatePricingRulesPayload } from '../../modules/api/pricing-rules';
import { AppState } from '../store';

export const pricingRulesSelector = (state: AppState) => {
  const rules = state.pricingRules?.rules;
  return Object.entries(rules ?? {})
    .filter(([_, v]) => v.status !== 'deleted')
    .map(([ruleId, rule]) => ({ ...rule, ruleId }));
};

export const pricingRuleSelector = (state: AppState, { ruleId }: { ruleId: string }) => {
  return state.pricingRules?.rules[ruleId];
};

/**
 * Gets the payload for the updatePricingRules lambda
 */
export const updatePayloadSelector = (state: AppState): UpdatePricingRulesPayload => {
  const rules = Object.values(state.pricingRules?.rules)
    .filter((rule) => rule.status === 'edited')
    .map((rule) => [rule.name, rule]);
  return {
    rules: Object.fromEntries(rules)
  };
};

export const deletePayloadSelector = (state: AppState) => {
  const deletedRules = Object.values(state.pricingRules?.rules)
    .filter((rule) => rule.status === 'deleted')
    .map((rule) => rule.name);
  return {
    rules: deletedRules
  };
};

export const pendingSaveSelector = createSelector(
  updatePayloadSelector,
  deletePayloadSelector,
  (updatePayload, deletePayload) => {
    return Object.values(updatePayload.rules || {}).length > 0 || deletePayload.rules.length > 0;
  }
);

export const savingSelector = (state: AppState) => state.pricingRules.savingRules;

/**
 * Return the loading state for pricing rules
 */
export const pricingRulesLoadingSelector = (state: AppState) => {
  return state.pricingRules.loading ?? true;
};

/**
 * Return the error state for pricing rules
 */
export const pricingRulesErrorSelector = (state: AppState) => {
  return state.pricingRules.error ?? false;
};
