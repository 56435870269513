import { OwnUpComponentLibraryProvider, OwnUpSpinner } from '@rategravity/own-up-component-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { DEFAULT_LENDER_ID } from '../../modules/rules/schema/constants';
import { loadLenderRulesAction } from '../../redux/lender-rules/actions';
import { lenderRulesLoadingSelector } from '../../redux/lender-rules/selector';
import { loadPricingRulesAction } from '../../redux/pricing-rules/actions';
import { pricingRulesLoadingSelector } from '../../redux/pricing-rules/selector';
import { AppState } from '../../redux/store';
import { PricingRuleEditorPage } from './pricing-rule-editor';
import { PricingRulesPage } from './pricing-rules';

export const PricingRulesApp = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const basePath = routeMatch.path;

  useEffect(() => {
    dispatch(loadPricingRulesAction());
    dispatch(loadLenderRulesAction(DEFAULT_LENDER_ID));
  }, [dispatch]);

  const pricingRulesLoading = useSelector(pricingRulesLoadingSelector);
  const defaultLoading = useSelector((state: AppState) =>
    lenderRulesLoadingSelector(state, { lenderId: DEFAULT_LENDER_ID })
  );
  if (pricingRulesLoading || defaultLoading) {
    return <OwnUpSpinner />;
  }
  return (
    <OwnUpComponentLibraryProvider>
      <Switch>
        <Route
          path={`${basePath}/editor/rule/:ruleId`}
          render={({ match }) => (
            <PricingRuleEditorPage
              ruleId={match.params.ruleId}
              onDone={() => {
                history.push(`${basePath}`);
              }}
            />
          )}
        />
        <Route path={basePath}>
          <PricingRulesPage
            onRuleEdit={(ruleId) => history.push(`${basePath}/editor/rule/${ruleId}`)}
          />
        </Route>
      </Switch>
    </OwnUpComponentLibraryProvider>
  );
};
